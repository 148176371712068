<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const commonData = inject("commonData");

const { data: footerData } = useCustomFetch("/api/strapi/footer");
const { data: footerColumnsData } = useCustomFetch("/api/strapi/footer-links");

const footerColumns = computed(() => {
  if (!footerColumnsData.value || footerColumnsData.value.length === 0) {
    return [
      { id: 1, attributes: { linkGroups: [] } },
      { id: 2, attributes: { linkGroups: [] } },
    ];
  }

  if (footerColumnsData.value.length === 1) {
    const groups = footerColumnsData.value[0].attributes.linkGroups;
    const midPoint = Math.ceil(groups.length / 2);
    return [
      { id: 1, attributes: { linkGroups: groups.slice(0, midPoint) } },
      { id: 2, attributes: { linkGroups: groups.slice(midPoint) } },
    ];
  }

  return footerColumnsData.value;
});

const sortedFooterColumns = computed(() => {
  const getLinkCount = (column) =>
    column.attributes.linkGroups.reduce(
      (sum, group) => sum + group.links.length,
      0,
    );

  return [...footerColumns.value].sort(
    (a, b) => getLinkCount(b) - getLinkCount(a),
  );
});
</script>

<template>
  <section v-if="footerData && footerColumns" id="footer" class="main-footer">
    <div class="footer-container">
      <div class="footer-wrapper">
        <div class="footer-column-left">
          <NuxtLinkLocale to="/" class="footer-logo">
            <img :src="$getStrapiImageSrc(commonData.logoWhite)" alt="Logo" />
          </NuxtLinkLocale>

          <div class="download-links footer-download-links">
            <NuxtLinkLocale
              v-for="link in footerData.downloads"
              :key="`download_${link.id}`"
              :to="link.link"
              :title="link.name"
            >
              <img
                :src="$getStrapiImageSrc(link.image)"
                :alt="link.name"
                width="142"
              />
            </NuxtLinkLocale>
          </div>

          <div v-if="footerData.contact" class="contact-info">
            <h2>
              {{ footerData.contact.name }}
              <small>
                <template
                  v-for="(contact, index) in footerData.contact.links"
                  :key="`contact_${contact.id}`"
                  style="display: flex; flex-direction: row;"
                >
                  <NuxtLinkLocale :to="contact.url">
                    {{ contact.name }}
                  </NuxtLinkLocale>

                  <span
                    v-if="index + 1 < footerData.contact.links.length"
                    style="margin-left: 10px"
                  >
                    {{ contact.phone }}
                  </span>
                </template>
              </small>
            </h2>
          </div>
        </div>
        <div class="footer-column-right">
          <ul class="footer-menu">
            <li
              v-for="column in sortedFooterColumns"
              :key="`column_${column.id}`"
            >
              <template
                v-for="(group, index) in column.attributes.linkGroups"
                :key="`group_${group.id}`"
              >
                <h2 :class="{ 'promo-block': index > 0 }">{{ group.name }}</h2>

                <ul>
                  <li v-for="link in group.links" :key="`link_${link.id}`">
                    <NuxtLinkLocale
                      :to="link.url"
                      :target="link.openInNewTab ? '_blank' : '_self'"
                    >
                      {{ link.name }}
                    </NuxtLinkLocale>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
        </div>
      </div>

      <div class="payment-share-section">
        <div class="payment-share-wrapper">
          <div v-if="footerData.socialsItems" class="social-share-links">
            <p class="share-text">
              {{ footerData.socialsTitle || "" }}

              <NuxtLinkLocale
                v-for="item in footerData.socialsItems"
                :key="`social_${item.id}`"
                :to="item.link"
              >
                <img :src="$getStrapiImageSrc(item.image)" :alt="item.name" />
              </NuxtLinkLocale>
            </p>
          </div>
          <div class="payment-methods-container">
            <div class="payment-methods-wrapper">
              <div
                v-for="image in footerData.paymentMethodsItems?.data"
                :key="image.id"
                class="payment-method-item"
              >
                <img
                  :src="$getStrapiImageSrc(image, true)"
                  alt="Payment Method"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul v-if="footerData.additionalLinks" class="additional-links">
        <li
          v-for="link in footerData.additionalLinks"
          :key="`additional-link_${link.id}`"
        >
          <NuxtLinkLocale
            :to="link.url"
            :target="link.openInNewTab ? '_blank' : '_self'"
          >
            {{ link.name }}
          </NuxtLinkLocale>
        </li>
      </ul>

      <div
        v-if="footerData.text"
        class="footer-disclaimer"
        v-html="$mdRenderer.render(footerData.text)"
      ></div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "@/assets/tempscss/main.scss";

.main-footer {
  background: #000;
  color: #fff;
  padding: 40px 0;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .footer-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .footer-column-left {
    margin-bottom: 40px;

    @media (min-width: 768px) {
      flex: 0 0 30%;
      max-width: 30%;
      margin-bottom: 0;
    }
  }

  .footer-column-right {
    @media (min-width: 768px) {
      flex: 0 0 65%;
      max-width: 65%;
    }
  }

  .footer-logo img {
    height: 50px;
    max-width: 100%;
  }

  .download-links {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    img {
      height: 42px;
      width: auto;
      max-width: 100%;
    }
  }

  .footer-menu,
  .footer-menu ul {
    list-style: none !important;
    margin: 0;
    padding: 0;
  }

  .footer-menu {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    > li {
      flex: 1 1 calc(50% - 15px);
      color: #fff;
      font-size: 14px;
      line-height: 10px;

      @media (min-width: 768px) {
        flex: 1 1 calc(50% - 15px); // 2 columns on tablet
        max-width: calc(50% - 15px); // Ensure maximum width for 2 columns
      }

      @media (min-width: 992px) {
        flex: 1 1 calc(20% - 24px); // 5 columns on desktop (as originally provided)
        max-width: calc(20% - 24px); // Maintain original max-width for desktop
      }
    }

    li h2 {
      font-size: 20px;
      margin-bottom: 15px;

      @media (min-width: 768px) {
        font-size: 26px;
      }
    }

    li a {
      color: #fff;
      text-decoration: none;
      transition: opacity 0.2s ease;
      display: inline-block;
      padding: 5px 0;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .contact-info {
    margin-top: 40px;

    a {
      text-decoration: none;
      color: inherit;
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 0.8;
      }
    }

    h2 {
      color: #fff;
      font-size: 20px;

      @media (min-width: 768px) {
        font-size: 25px;
      }

      small {
        font-size: 13px;
        display: block;
        margin-top: 5px;
      }
    }
  }

  .payment-share-section {
    border-top: 1px solid #1b1d26;
    padding: 20px 0;
    border-bottom: 1px solid #1b1d26;
    margin-top: 40px;

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    .social-share-links,
    .payment-methods {
      width: 100%;

      @media (min-width: 768px) {
        width: auto;
      }
    }

    .share-text,
    .payments {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 0;
    }

    .share-text {
      color: #fff;
      font-size: 12px;

      img {
        height: 30px;
        width: auto;
      }

      a {
        background: #222221;
        display: inline-block;
        border-radius: 5px;
        transition: opacity 0.2s ease;
        padding: 5px;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .payments {
      span {
        color: #fff;
        line-height: 17px;
        font-size: 14px;
      }

      img {
        height: 35px;
        width: auto;
      }
    }
  }

  .promo-block {
    margin-top: 30px;
  }

  .additional-links {
    padding: 0;
    list-style: none;
    margin: 20px auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;

    a {
      color: #747474;
      text-decoration: none;
      transition: color 0.2s ease;
      display: inline-block;
      padding: 5px 0;

      &:hover {
        color: #fff;
      }
    }
  }

  .footer-disclaimer {
    color: #fff;
    padding-top: 20px;
    text-align: justify;
    font-size: 15px;
    line-height: 1.5;
  }
}

/* RTL Support */
[dir="rtl"] {
  .main-footer {
    .footer-wrapper {
      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }
    }

    .footer-menu {
      > li {
        text-align: right;
      }
    }

    .payment-share-section-wrapper {
      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }
    }

    .share-text,
    .payments {
      flex-direction: row-reverse;
    }

    .additional-links {
      flex-direction: row-reverse;
    }

    .footer-disclaimer {
      text-align: right;
    }
  }
}

/* Larger screen styles (unchanged) */
@media (min-width: 1025px) {
  .main-footer {
    padding: 80px 0;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }
}

.payment-methods-container {
  margin-top: 1rem; // equivalent to tw-mt-4
  display: flex;
  justify-content: center;

  .payment-methods-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem; // equivalent to tw-gap-4

    .payment-method-item {
      img {
        height: auto;
        max-width: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
