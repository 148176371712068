<script setup lang="ts">
import { ref, computed, inject } from "vue";
import type { Link } from "~/types/common";

const isMenuButtonHidden = ref(false);
const isMobileMenuOpen = ref(false);
const isTopLinksOpen = ref(false);
const mainMenuDisplay = ref("");

function onMenuButtonClick() {
  isMenuButtonHidden.value = !isMenuButtonHidden.value;
  isMobileMenuOpen.value = !isMobileMenuOpen.value;
  isTopLinksOpen.value = !isTopLinksOpen.value;
  mainMenuDisplay.value = mainMenuDisplay.value === "block" ? "" : "block";
}

function onMenuItemClick(e: Event) {
  const target = e.target as HTMLElement;
  if (target.tagName === "A" && !target.closest(".menu-item-has-children")) {
    isMobileMenuOpen.value = false;
    isTopLinksOpen.value = false;
    mainMenuDisplay.value = "";
  }
}

const { locale, locales, setLocale } = useI18n();

const localeInfo = computed(() => {
  return (
    locales.value as any as { code: string; language: string; name: string }[]
  ).find((l: { code: string }) => l.code === locale.value);
});

interface HeaderData {
  loginButtonText: string;
  signupButtonText: string;
  loginButtonDropdown: {
    id: number;
    name: string;
    url: string;
    openInNewTab: boolean;
  }[];
}

interface NavItemsData {
  id: number;
  attributes: {
    name: string;
    menu_position: number;
    content: ContentItem[];
  };
}

interface ContentItem {
  id: number;
  __component: string;
  links: Link[];
  name?: string;
  icon?: {
    data: {
      id: number;
      attributes: {
        url: string;
      };
    };
  };
}

const { data: navItems } = await useAsyncData("navItems", () =>
  $fetch<NavItemsData[]>("/api/strapi/header-nav-items"),
);
const { data: headerData } = await useAsyncData("headerData", () =>
  $fetch<HeaderData>("/api/strapi/header"),
);

const sortedNavItems = computed(() => {
  return navItems.value?.sort(
    (a, b) => a.attributes.menu_position - b.attributes.menu_position,
  );
});

const commonData = inject("commonData") as { logoColored: string };

function toggleSubmenu(event: Event) {
  const target = event.target as HTMLElement;
  if (
    process.client &&
    window.innerWidth <= 1200 &&
    target.closest(".menu-item-has-children")
  ) {
    event.preventDefault();
    const menuItem = target.closest(".menu-item-has-children") as HTMLElement;
    menuItem.classList.toggle("active");
  }
}

onMounted(() => {
  if (process.client) {
    const menuItems = document.querySelectorAll(".menu-item-has-children > a");
    menuItems.forEach((item) => item.addEventListener("click", toggleSubmenu));
  }
});
</script>

<template>
  <section v-if="headerData && sortedNavItems && commonData" id="header">
    <div class="container">
      <div class="row">
        <!-- Logo and Language Selector -->
        <div class="col-md-4">
          <NuxtLink :to="localePath('/')" class="logo">
            <img :src="$getStrapiImageSrc(commonData.logoColored)" alt="Logo" />
          </NuxtLink>
          <!-- Desktop Language Selector -->
          <div class="lang desktop-lang">
            <span class="d-inline-flex align-items-center">
              <span
                :class="`fi fis rounded-circle fi-${localeInfo?.language}`"
                style="font-size: 26px"
              ></span>
              <span class="langcode">{{ localeInfo?.code.toUpperCase() }}</span>
            </span>

            <div class="lang-options">
              <div v-for="loc in locales" :key="loc.code">
                <a
                  class="d-flex align-items-center"
                  @click.prevent="setLocale(loc.code)"
                >
                  <span
                    :class="`fi fis rounded-circle fi-${loc?.language}`"
                    style="font-size: 26px"
                  ></span>
                  &nbsp; {{ loc.name }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- Main Menu and Buttons -->
        <div class="col-md-8">
          <div class="d-flex justify-content-xl-end w-100 gap-3">
            <!-- Mobile Language Selector -->
            <div class="lang mobile-lang">
              <span class="d-inline-flex align-items-center">
                <span
                  :class="`fi fis rounded-circle fi-${localeInfo?.language}`"
                  style="font-size: 26px"
                ></span>
                <span class="langcode">{{
                  localeInfo?.code.toUpperCase()
                }}</span>
              </span>

              <div class="lang-options">
                <div v-for="loc in locales" :key="loc.code">
                  <a
                    class="d-flex align-items-center"
                    @click.prevent="setLocale(loc.code)"
                  >
                    <span
                      :class="`fi fis rounded-circle fi-${loc?.language}`"
                      style="font-size: 26px"
                    ></span>
                    &nbsp; {{ loc.name }}
                  </a>
                </div>
              </div>
            </div>
            <a
              id="menubutton"
              :class="{ hidden: isMenuButtonHidden }"
              @click="onMenuButtonClick"
            />
            <ul
              class="mainmenu w-100 d-xl-inline-flex justify-content-xl-end"
              :class="{ show: isMobileMenuOpen }"
              :style="{ display: isMobileMenuOpen ? 'block' : 'none' }"
              @click="onMenuItemClick"
            >
              <!-- Main Menu Items -->
              <li class="abc only-tablet">
                <ul
                  class="mainmenu w-100"
                  :class="{ show: isMobileMenuOpen }"
                  :style="{ display: mainMenuDisplay }"
                >
                  <li id="tablet-login-btn" class="menu-item-has-children">
                    <a class="buttons outline" href="#">{{
                      headerData.loginButtonText
                    }}</a>
                    <ul class="sub-menu">
                      <li
                        v-for="item in headerData.loginButtonDropdown"
                        :key="item.id"
                      >
                        <NuxtLink
                          :to="localePath(item.url)"
                          :target="item.openInNewTab ? '_blank' : '_self'"
                        >
                          {{ item.name }}
                        </NuxtLink>
                      </li>
                    </ul>
                  </li>
                </ul>
                <NuxtLink class="buttons inline" :to="localePath('/register')">
                  {{ headerData.signupButtonText }}
                </NuxtLink>
              </li>

              <li
                v-for="item in sortedNavItems"
                :key="item.id"
                class="menu-item-has-children"
              >
                <a href="#">{{ item.attributes.name }}</a>
                <ul
                  class="sub-menu"
                  :class="{ showblocks: item.attributes.content.length > 1 }"
                >
                  <template
                    v-for="content in item.attributes.content"
                    :key="`content_${content.id}`"
                  >
                    <template v-if="content.__component === 'header.nav-group'">
                      <li
                        v-for="link in content.links"
                        :key="`link_${link.id}`"
                      >
                        <NuxtLink
                          :to="localePath(link.url)"
                          :target="link.openInNewTab ? '_blank' : '_self'"
                        >
                          {{ link.name }}
                        </NuxtLink>
                      </li>
                    </template>

                    <div
                      v-if="content.__component === 'header.nav-column'"
                      class="colblock"
                      :style="{
                        '--_icon': content.icon
                          ? `url(${$getStrapiImageSrc(content.icon)})`
                          : 'none',
                      }"
                    >
                      <li style="padding-right: 10px">
                        <span
                          style="text-wrap: nowrap"
                          v-html="$mdRenderer.renderInline(content.name)"
                        ></span>
                      </li>
                      <li
                        v-for="columnLink in content.links"
                        :key="`column_link_${columnLink.id}`"
                      >
                        <NuxtLink
                          :to="localePath(columnLink.url)"
                          :target="columnLink.openInNewTab ? '_blank' : '_self'"
                        >
                          {{ columnLink.name }}
                        </NuxtLink>
                      </li>
                    </div>
                  </template>
                </ul>
              </li>
            </ul>
            <div
              id="header-login-signup"
              class="d-flex gap-3 flex-nowrap justify-content-end"
            >
              <ul class="mainmenu">
                <li id="tablet-login-btn" class="menu-item-has-children">
                  <a class="buttons outline text-nowrap" href="#">
                    {{ headerData.loginButtonText }}
                  </a>
                  <ul class="sub-menu">
                    <li
                      v-for="item in headerData.loginButtonDropdown"
                      :key="item.id"
                    >
                      <NuxtLink
                        :to="localePath(item.url)"
                        :target="item.openInNewTab ? '_blank' : '_self'"
                      >
                        {{ item.name }}
                      </NuxtLink>
                    </li>
                  </ul>
                </li>
              </ul>
              <div class="toplinks" :class="{ open: isTopLinksOpen }">
                <NuxtLink
                  class="buttons inline text-nowrap"
                  :to="localePath('/register')"
                >
                  {{ headerData.signupButtonText }}
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "@/assets/tempscss/main.scss";
#header {
  border-bottom: 1px solid #ececec;
  box-shadow: 0 20px 60px -2px rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  font-weight: 500;
  position: relative;
  background: rgba(255, 255, 255, 0.8);
  z-index: 99;
  backdrop-filter: blur(60px);
  position: fixed;
  right: 0px;
  left: 0px;
  z-index: 999 !important;
}

#header .logo {
  display: block;
  float: left;
}

#header .logo img {
  height: 35px;
}

#header .lang {
  float: left;
  margin-left: 40px;
}

#header .arrow {
  background: url(/img/arrowdown.png) no-repeat;
  background-size: 100%;
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 2px;
  float: left;
  margin-top: 12px;
}

#header .langflag {
  margin-left: 5px;
  margin-top: -2px;
}

#header .langcode {
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 12px;
  display: inline-block;
  padding-bottom: 5px;
}

#header .langcountry {
  padding-left: 7px;
}

#header .mainmenu {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

#header .mainmenu li a {
  text-decoration: none;
  padding: 5px 18px;
  display: inline-block;
  font-size: 16px;
  color: #1c1c1c;
}

#header .buttons {
  text-decoration: none;
  padding: 3px 28px;
  display: inline-block;
  border: 1px solid #cccccc;
  border-radius: 5px;
  color: #1c1c1c;
  font-size: 18px;
}

#header .buttons.inline,
#tradesmart.partnerstradesmart .wrappertrade .buttons {
  background: #190787;
  color: #fff;
  border-color: #190787;
}

#header .buttons.inline:hover,
#favoritemarkets .contentblock .buttons:hover,
#bigplayer .wraperbig .buttons:hover {
  box-shadow: 10px 0px 30px 0px rgba(25, 7, 135, 0.3);
  background: rgb(25, 7, 135);
  background: linear-gradient(315deg, rgb(25, 7, 135) 39%, rgb(13, 2, 80) 88%);
  border-color: #0d0250;
  transition: all 0.5s ease;
}

#header .buttons.outline:hover {
  box-shadow: 10px 0px 30px 0px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: #000;
  transition: all 0.5s ease;
}

#blocks .glassblock .buttons {
  text-decoration: none;
  display: inline-block;
  padding: 5px 45px;
  border-radius: 50px;
  border: 1px solid #ececec;
  color: #1b1b1b;
  font-weight: 600;
}

#blocks .glassblock .buttons:hover {
  box-shadow: 10px 0px 30px 0px rgba(0, 0, 0, 0.12);
  background: #fff;
  border-color: #fff;
  color: #000;
  transition: all 0.5s ease;
  border: 1px solid #ececec;
}

#tradesmart .toplinks {
  margin-top: 95px;
  margin-bottom: 60px;
}

#tradesmart .toplinks a {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  border-radius: 50px;
  border: 1px solid #2c0cee;
  padding: 0px 25px;
  margin-right: 15px;
}

#tradesmart .toplinks a:hover {
  border-color: #fff;
}

#tradesmart .toplinks a.active,
#tradesmart .toplinks li.active a {
  border: 1px solid #fff;
}

#tradesmart .wrappertrade .buttons {
  border-radius: 50px;
  display: inline-block;
  text-decoration: none;
  background: #fff;
  padding: 7px 20px;
  font-size: 20px;
  font-weight: 700;
  color: #190787;
  margin-bottom: 95px;
  border: 1px solid #fff;
}

#tradesmart .wrappertrade .buttons:hover {
  transition: all 0.5s ease;
  background: transparent;
  color: #fff;
}

#favoritemarkets .toplinks {
  display: table;
  margin: 0 auto;
  margin-top: 20px;
  list-style: none;
  padding: 0px;
  margin-bottom: 30px;
}

#favoritemarkets .toplinks li {
  display: inline-block;
  text-decoration: none;
  padding: 5px 35px;
  border-radius: 50px;
  border: 1px solid #ececec;
  color: #565656;
  margin: 0 5px;
  background: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

#favoritemarkets .toplinks li.active,
#favoritemarkets .toplinks li:hover {
  box-shadow: 5px 0px 30px 0px rgba(0, 0, 0, 0.07);
}

#favoritemarkets .contentblock .buttons,
#bigplayer .wraperbig .buttons {
  display: inline-block;
  text-decoration: none;
  padding: 7px 25px;
  background: #190787;
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  margin-right: 15px;
  margin-top: 40px;
  margin-bottom: 40px;
}

#vippromo .promoblock .buttons {
  border-radius: 50px;
  background: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 7px 35px;
  margin-bottom: 20px;
  margin-right: 20px;
  font-size: 18px;
  font-weight: 700;
}

#vippromo .promoblock .buttons span {
  background: #2c1f12;
  background: linear-gradient(to right, #2c1f12 0%, #daac40 49%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#callto .buttons,
#maincontentblock .buttons,
.faqspage .headlinesblocks .buttons {
  width: auto;
  display: table;
  margin: 0 auto;
  padding: 10px 60px;
  text-decoration: none;
  background: #190787;
  border-radius: 20px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-top: 30px;
  position: relative;
  z-index: 99;
  box-shadow: 10px 30px 60px 0px rgba(25, 7, 135, 0.3);
}

#menubutton,
.only-tablet,
.lang-options {
  display: none;
}

#header .mainmenu .menu-item-has-children:hover .sub-menu {
  visibility: visible;
  transform: translateY(0%);
  opacity: 1;
}

#header .mainmenu .sub-menu {
  visibility: hidden;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  background: #fff;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.05);
  padding: 10px;
  min-width: 220px;
  width: auto;
  transition:
    transform 0.3s,
    opacity 0.3s;
  transform: translateY(30px) translateY(0%);
  opacity: 0;
  border-radius: 10px;
  z-index: 99;
}

.elevateblock .buttons,
.platformblock .buttons {
  font-size: 16px !important;
  display: inline-block !important;
  margin-right: 10px !important;
  border-radius: 150px !important;
  padding: 5px 30px !important;
  box-shadow: none !important;
}

.platformblock .buttons:last-child {
  font-weight: 400 !important;
}

#maincontentblock .blockglasser .buttons {
  display: table !important;
  margin: 0 auto !important;
  width: auto !important;
}

.alertblockcall .buttons {
  text-align: center;
  display: inline-block;
  padding: 7px;
  background: #190787;
  color: #fff;
  text-decoration: none;
  width: 220px;
  border-radius: 50px;
  font-weight: 700;
  font-size: 20px;
  margin: 0 15px;
}

#maincontentblock.ibsection .buttons {
  background: #fff;
  color: #190787;
  font-size: 1.3em !important;
  font-weight: 700 !important;
}

#maincontentblock.affiliates .wrapperblock .buttons {
  background: #190787;
  color: #fff;
  font-size: 1.3em !important;
  font-weight: 700 !important;
}

#header .mainmenu .sub-menu li,
#header
  div.container
  div.row
  div.col-md-8
  div#header-login-signup
  ul.mainmenu
  li.menu-item-has-children
  a.buttons.outline,
#header
  div.container
  div.row
  div.col-md-8
  div#header-login-signup
  div.toplinks
  a.buttons.inline {
  width: 100%;
}

#maincontentblock.copytrading .offerus .buttons {
  text-align: center;
  display: table !important;
  padding: 7px 35px;
  background: #190787;
  color: #fff;
  text-decoration: none;
  border-radius: 50px;
  font-weight: 700;
  font-size: 20px;
  margin: 20px auto !important;
  width: auto;
  float: none !important;
}

#maincontentblock.contactpage .glassess .buttons {
  background: #fff;
  box-shadow: none;
  border: 1px solid #ececec;
  color: #190787;
  font-weight: 500;
  font-size: 17px;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
}

.contactpage #cntforms .buttons {
  margin: 10px 0;
  border: none;
  padding: 8px 70px;
}

.cfdtrading .sectioncfd .glassgloqhymarkets .headlinesbtm .buttons {
  box-shadow: none !important;
}

.modal-content .buttons {
  border-radius: 20px;
  display: table;
  padding: 10px 45px;
  background: #160577;
  margin: 0 auto;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  font-size: 18px;
  box-shadow: 10px 30px 60px 0px rgba(25, 7, 135, 0.3);
}

#maincontentblock.careersblock .buttons {
  background: #190787;
  padding: 10px 35px !important;
  color: #fff;
}

.tabscontent .buttons {
  border-radius: 10px !important;
  font-weight: 400 !important;
  margin-top: 10px !important;
}

#tradesmart ul#tabs-nav.toplinks {
  list-style: none;
  padding: 0px;
}

#tradesmart ul#tabs-nav.toplinks li {
  float: left;
}

#header {
  backdrop-filter: blur(10px);
}

#tradesmart .wrappertrade .buttons {
  font-weight: 600;
}

.mainmenu {
  display: block;
}

#header-login-signup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

#header-login-signup .mainmenu {
  margin-left: 0;
}

#header
  div.container
  div.row
  div.col-md-8
  div#header-login-signup
  ul.mainmenu
  li.menu-item-has-children
  a.buttons.outline,
#header
  div.container
  div.row
  div.col-md-8
  div#header-login-signup
  div.toplinks
  a.buttons.inline {
  text-align: center;
}

#header
  div.container
  div.row
  div.col-md-8
  ul.mainmenu
  li.menu-item-has-children
  ul.sub-menu.showblocks
  div.colblock
  li
  strong {
  font-weight: 700;
}

#header
  div.container
  div.row
  div.col-md-8
  ul.mainmenu
  li.menu-item-has-children.only-tablet
  ul.mainmenu
  li.menu-item-has-children
  a.buttons.outline {
  background-color: #fff !important;
  position: relative !important;
  right: 0 !important;
}

#header
  div.container
  div.row
  div.col-md-8
  ul.mainmenu
  li.menu-item-has-children.only-tablet
  a.buttons.inline::after {
  content: none !important;
}

.lang-options a {
  text-decoration: none;
  color: #000;
}

.lang-options div {
  margin-left: 15px;
  margin-top: 10px;
}

.lang:hover {
  cursor: pointer;
}

.lang-options {
  display: block;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  background: #fff;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.05);
  padding: 10px;
  width: 220px;
  border-radius: 10px;
  transform: translateY(30px);
  transition: transform 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
}

.lang:hover > .lang-options {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.gap-3 {
  gap: 1rem !important;
}

.slide-buttons .buttons {
  border-radius: 50px;
  display: inline-block;
  text-decoration: none;
  padding: 10px 35px;
  font-size: 20px;
  font-weight: 600;
}

.slide-buttons .buttons.inline {
  background: #190787;
  border-color: #190787;
  color: #fff;
}

.slide-buttons .buttons.inline:hover {
  box-shadow: 10px 0px 30px 0px rgba(25, 7, 135, 0.3);
  background: rgb(25, 7, 135);
  background: linear-gradient(315deg, rgb(25, 7, 135) 39%, rgb(13, 2, 80) 88%);
  border-color: #0d0250;
}

.slide-buttons .buttons.outline {
  color: #cccccc;
  border: 1px solid #cccccc;
}

.slide-buttons .buttons.outline:hover {
  box-shadow: 10px 0px 30px 0px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: #000;
}

#header {
  .desktop-lang {
    display: block !important;
  }

  .mobile-lang {
    display: none !important;
  }
}

@media all and (max-width: 1200px) {
  #menubutton {
    display: block !important;
    background: url(/img/menu-button.svg) no-repeat center !important;
    background-size: 35px !important;
    height: 50px !important;
    width: 50px !important;
    position: absolute !important;
    right: 48px !important;
    top: -10px !important;
  }

  #header {
    .desktop-lang {
      display: block !important;
    }

    .mobile-lang {
      display: none !important;
    }
  }
}

@media all and (max-width: 768px) {
  .mobile-lang {
    display: none !important; // Mobile still hidden
  }
  #menubutton {
    background-size: 22px !important;
  }

  #header {
    .desktop-lang {
      display: none !important;
    }

    .mobile-lang {
      display: block !important;
      position: absolute !important;
      right: 72px !important;
      top: 50% !important;
      transform: translateY(-50%) !important;
    }
  }
}

@media all and (max-width: 767px) {
  #menubutton {
    display: block !important;
    background: url(/img/menu-button.svg) no-repeat center !important;
    height: 50px !important;
    width: 50px !important;
    background-size: 35px !important;
    position: absolute !important;
    right: 6px !important;
  }

  #header .mobile-lang {
    right: 56px !important;
    top: -1px !important;
    transform: none !important;
  }
}

@media all and (max-width: 768px) {
  #header {
    .mobile-lang {
      .lang-options {
        right: 0;
        left: auto;
        transform-origin: top right;
      }
    }
  }
}

@media all and (max-width: 767px) {
  #header {
    .mobile-lang {
      .lang-options {
        right: 0;
        top: 100%;
        transform: translateY(0);
      }
    }
  }
}
</style>
